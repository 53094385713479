import Vue from 'vue'
import vuei18n from "vue-i18n"    //引入vue-i18n插件
import zh from "./zh_cn"             //导入zh.js中文语言包
import en from "./en_us"             //导入en.js中文语言包
Vue.use(vuei18n)
const messages = {
  en: {
    ...en,    //使用ES6扩展运算符解构en
  },
  zh: {
    ...zh,
  },
}
const lang = (navigator.language || 'en').toLocaleLowerCase()
const language = localStorage.getItem('language') || lang.split('-')[0] || 'zh';
// const language = 'en';
// console.log("LLLLLLLLLLLLLLLLL",language)
const i18n = new vuei18n({
  locale: language,
  legacy: false,
  globalInjection: true,
  messages,
})

export default i18n;