<template>
	<div class="home-page" v-if="!showCheck">
		<!--轮播图 -->
		<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
			<van-swipe-item v-for="item in picArr" :key="item">
				<img :src="item" alt="" />
			</van-swipe-item>
		</van-swipe>
		<div class="swipemask">
			<h4>
				<img class="safeIcon" src="../assets/img/home/icon.svg" alt="" />
				{{ $t('toptips') }}
			</h4>
			<div class="scanCode">
				<h5>{{ ScanNum }}</h5>
				<span>{{ $t('scantimes') }}</span>
			</div>
		</div>
		<div class="cp">
			<h4>区块链数字身份证，一物一码，正品保障</h4>
		</div>

		<div class="contain">
			<!-- 产品信息区域 -->
			<dl class="productionInfo">
				<dt class="infoTitle">{{ $t('productinfo') }}</dt>
				<dd class="infoList">
					<span class="infoLabel">{{ $t('traning_code') }}：{{ traceNo == "" ? "替代文字" : traceNo }}-{{ this.num
					}}</span>
					<!-- <br />
					<span class="contentText">{{ traceNo == "" ? "替代文字" : traceNo }}-{{ this.num }}</span> -->
				</dd>
				<dd class="infoList">
					<span class="infoLabel">{{ $t('batch_no') }}：{{ batchNo }}</span>
					<!-- <br />
					<span class="contentText">{{ batchNo }}</span> -->
				</dd>

				<dd class="infoList">
					<span class="infoLabel">{{ $t('product_name') }}：{{ productInfo.name }}</span>
					<!-- <br />
					<span class="contentText">{{ productInfo.name }}</span> -->
				</dd>
				<!-- item for1 -->
				<dd v-for="item in addonData" :key="item.name" class="infoList">
					<div class="infoLabel">{{ item.name }}：<span v-if="item.type == 'txt'" class="contentText">{{
						item.contentFile }}</span></div>
					<br />
					<!-- 文本类型 -->
					<!-- <span v-if="item.type == 'txt'" class="contentText">
						{{ item.contentFile }}
					</span> -->
					<!-- 图片类型 -->
					<div v-if="item.type == 'image'" class="imageList">
						<!-- value no2 -->
						<img v-for="(value, index) in item.contentFile" class="contentText imageContent"
							:src="`${imgBaseUrl}/${value.submitSrc}`" @click="previeewEnv(index, item.contentFile)"
							:key="value.submitSrc" />
					</div>
					<!-- 视频类型 -->
					<video controls :src="`${imgBaseUrl}/${item.contentFile[0].submitSrc}`" onloadeddata='this.currentTime=1' onplay='this.currentTime=0' v-if="item.type == 'video'"
						class="contentText videoContent">
						{{ $t('not_support_video') }}
					</video>
					<!-- pdf文件类型 -->
					<span v-if="item.type == 'pdf'" class="contentText pdfContent" @click="previewPdf(item.contentFile)">
						<van-icon name="description" />
						{{ item.contentFile[0].name }}
					</span>
				</dd>
				<dd>
					<div class="identification" v-if="productInfo.txhash != ''">
						<button id="identification" @click="traceToSource('production', productInfo)" type="info"
							class="idCationBtn">
							{{ $t('txhash') }} ：{{ productInfo.txhash }}
						</button>
					</div>
				</dd>
			</dl>
			<!-- 溯源环节 -->
			<!-- item for1 -->
			<dl class="productionInfo" v-for="item in processList" :key="item.id">
				<dt class="infoTitle">{{ item.name }}</dt>
				<!-- value for2 -->
				<dd v-for="(value, i) in JSON.parse(item.addonData)" :key="value.name + i" class="infoList">
					<div class="infoLabel">{{ value.name }}：<span v-if="value.type == 'txt'">{{ value.contentFile }}</span>
					</div>
					<!-- <br /> -->
					<!-- 文本类型 -->
					<!-- <span v-if="value.type == 'txt'" class="contentText">
						{{ value.contentFile }}
					</span> -->
					<!-- 图片类型 -->
					<div v-if="value.type == 'image'" class="imageList">
						<img v-for="(val, index) in value.contentFile" class="contentText imageContent"
							:src="`${imgBaseUrl}${val.submitSrc}`" @click="previeewEnv(index, value.contentFile)" />
					</div>
					<!-- 视频类型 -->
					<video controls :src="`${imgBaseUrl}/${value.contentFile[0].submitSrc}`" onloadeddata='this.currentTime=1' onplay='this.currentTime=0' v-if="value.type == 'video'"
						class="contentText videoContent">
						{{ $t('not_support_video') }}
					</video>
					<!-- pdf文件类型 -->
					<span v-if="value.type == 'pdf'" class="contentText pdfContent" @click="previewPdf(value.contentFile)">
						<van-icon name="description" />
						{{ value.contentFile[0].name }}
					</span>
				</dd>

				<dd>
					<!-- 区块链标识 -->
					<div class="identification">
						<button id="identification" @click="traceToSource('process', item)" type="info" class="idCationBtn">
							{{ $t('blockchain_ident') }}：{{ item.txhash }}
						</button>
					</div>
				</dd>
			</dl>
		</div>

		<Footer class="footer"></Footer>
	</div>
	<div class="home-check" v-else>
		<div class="headerBg">
			<div class="checkTop">#{{ $t('queryTitle') }}#</div>
		</div>
		<div class="checkCode">
			<div class="checkProd">{{ $t('goodsInfo') }}：
				<p><b>{{ productInfo.name }}</b></p>
			</div>

			<div class="checkContent">
				<div class="checkText">{{ $t('inputCheckCode') }}</div>
				<div class="formcheck">
					<div class="inputCheck">
						<input type="number" class="inputCheckInp" v-model="checkCode" maxlength="6"
							:placeholder="$t('input_code')" @input="checkInputCheckCode" />
					</div>

					<div class="checkBtn">
						<van-button round block type="info" @click="subCheck()" square native-type="submit"
							style="width:80%; margin:0 auto; border-radius:5px;">{{ $t('query') }}</van-button>
					</div>
				</div>

				<div class="checktips">
					<!-- <img class="safeIcon" src="../assets/img/home/u11.svg" alt=""  style="width:20px;"/> -->
					<van-icon name="shield-o" color="#1989fa" />
					{{ $t('tips2') }}
				</div>
				<div class="logo"><img src="../assets/img/Identification/hx-logo.png" alt="" style="height:30px"></div>

			</div>
		</div>
	</div>
</template>

<script>
// import Vue from "vue";

import { ImagePreview } from "vant";
import { getInfo, check } from "../network/home.js";
import Footer from "../components/footer/Footer.vue";
import { Toast } from 'vant';
export default {
	name: "Home",
	components: { Footer },
	data() {
		return {
			showCheck: true,
			picArr: [],
			productInfo: {}, //产品信息
			processList: [], //溯源环节
			batchNo: "", //产品批次
			traceNo: "", //溯源码
			ScanNum: "", //扫码次数
			addonData: "", //产品自定义数据
			bid: "",
			identityNo: "",
			pdfBaseUrl: "",
			num: "",
			checkCode: "",
		};
	},
	methods: {
		subCheck() {
			if (this.checkCode == "") {
				Toast({
					message: this.$t('input_code'),
				})
				return false
			}
			this.getInfo({ traceNo: this.traceNo, num: this.num, checkCode: this.checkCode })
		},
		checkInputCheckCode(value) {
			if (this.checkCode.length > 6) this.checkCode = this.checkCode.slice(0, 6)
		},
		// 前往溯源区块链
		traceToSource(type, data) {
			data = JSON.stringify(data);
			this.$router.push({ name: "identification", params: { data } });
		},
		// 获取区块链存证信息
		async getInfo(params) {
			const { data: res } = await getInfo(params);
			if (res.code == 200) {
				this.showCheck = false
				// 产品信息
				this.productInfo = res.data.ProductInfo;
				// 产品自定义数据
				if (res.data.ProductInfo.addonData) {
					this.addonData = JSON.parse(res.data.ProductInfo.addonData);
				}
				// 产品轮播图
				this.picArr = JSON.parse(res.data.ProductInfo.pics);
				this.picArr = this.picArr.map((v) => `${this.pdfBaseUrl}/` + v);

				// 溯源环节
				if (res.data.ProcessList) {
					this.processList = res.data.ProcessList;
				}
				// 批次号
				this.batchNo = res.data.BatchNo;
				// 溯源码
				this.traceNo = res.data.TraceNo;
				// 扫码次数
				this.ScanNum = res.data.ScanNum || 0;
				if (this.checkCode != "") {
					this.$router.push("/" + this.traceNo + "/" + this.num + "/" + this.checkCode)
					return false
				}
			} else {
				if (res.code == 400) {
					if (res.message.indexOf('不存在') > -1) {
						this.$router.push("/404")
					} else {
						Toast({
							message: this.$t('errCheckCode'),
							overlay: true,
							forbidClick: true,
						});
					}

				} else if (res.code == 429) {
					Toast({
						message: this.$t('tooManyQuery'),
						overlay: true,
						forbidClick: true,
					});
				}

			}
		},
		async checkCodeReq(params) {
			// if (this.checkCode != "") {
			// 	this.subCheck()
			// } else {
				const { data: res } = await check(params);
				if (res.data.check == true && typeof this.checkCode == "undefined") {
					this.showCheck = true
					this.productInfo = res.data.productInfo;
					// this.$roter.push()
				} else {
					this.getInfo({ traceNo: this.traceNo, num: this.num, checkCode: this.checkCode })
					this.showCheck = false
				}
			// }

		},

		//图片预览
		previeewEnv(index, environmentalList) {
			console.log("图片预览", environmentalList, index);
			environmentalList = environmentalList.map(
				(v) => `${this.pdfBaseUrl}/` + v.submitSrc
			);
			ImagePreview({
				images: environmentalList,
				startPosition: index, //预览图片索引
				closeable: true, //右上角关闭按钮是否显示
			});
		},

		//预览pdf
		previewPdf(contentFile) {
			if (contentFile[0] && contentFile[0].submitSrc) {
				// 当pdf文件地址包含域名时 切割域名  （由于数据变化所以做以下操作）
				// if (
				// 	contentFile[0].submitSrc.indexOf("http://183.3.158.22:8598/") != -1
				// ) {
				// 	let fileUrl = contentFile[0].submitSrc.match(
				// 		/http:\/\/183.3.158.22:8598\/(\S*)/
				// 	)[1];

				// 	return this.$router.push(
				// 		`/pdfPreview?fileUrl=${this.pdfBaseUrl}/${fileUrl}&fileName=${contentFile[0].name.split(".")[0]
				// 		}`
				// 	);
				// } else {
				// 若不含域名，直接跳转
				return this.$router.push(
					`/pdfPreview?fileUrl=${this.pdfBaseUrl}/${contentFile[0].submitSrc
					}&fileName=${contentFile[0].name.split(".")[0]}`
				);
				// }
			} else {
				// this.$router.push(`/pdfPreview?fileUrl=/pdf/${contentFile}`);
				this.$toast.fail("文件地址错误");
			}
		},
	},
	mounted() {

		this.traceNo = this.$route.params.traceNo;
		this.num = this.$route.params.num;
		this.checkCode = this.$route.params.code;
		this.checkCodeReq({ traceNo: this.traceNo, num: this.num });
		// this.getInfo({ traceNo: this.traceNo, num: this.num });
	},
	beforeMount() {
		// 图片 文件 视频 baseUrl地址
		this.imgBaseUrl = process.env.VUE_APP_IMG_BASE_URL;
		this.pdfBaseUrl = process.env.VUE_APP_PDF_BASE_URL;
	},
};
</script>

<style lang="less" scoped>
.home-page {
	max-width: 660px;
	margin: 0 auto;
	position: relative;
	background-color: #f3f5f4;

	.cp {
		text-align: center;
		margin-top: 20px;
		color: #f16262;
	}

	.my-swipe .van-swipe-item {
		height: 75vw;
		// color: #fff;
		font-size: 30px;
		line-height: 150px;
		text-align: center;
		background-color: #f3f5f4;
		display: flex;
		align-items: center;

		img {
			width: 100vw;
			// max-width: 660px;
			object-fit: contain;
		}
	}

	/deep/.van-swipe__indicators {
		bottom: 70px;

		.van-swipe__indicator {
			width: 8px;
			height: 8px;
		}
	}

	// 轮播图遮罩层
	.swipemask {
		width: 100vw;
		max-width: 660px;
		height: 60px;
		display: flex;
		justify-content: space-around;
		background-color: #102b02;
		filter: alpha(opacity=60);
		-moz-opacity: 0.8;
		-khtml-opacity: 0.8;
		opacity: 0.8;
		color: #fff;
		position: absolute;
		top: calc(75vw - 60px);
		left: 0;

		/deep/.safeIcon {
			width: 25px;
			height: 25px;
			vertical-align: sub;
		}

		h4 {
			font-weight: 400;
			line-height: 60px;
			font-size: 18px;
		}

		.scanCode {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;

			h5 {
				font-size: 16px;
			}

			span {
				font-size: 12px;
			}
		}
	}

	.contain {
		padding: 15px;
		background-color: #f3f5f4;
		min-height: calc(100vh - 300px);
	}

	// 溯源信息
	.productionInfo {
		border-radius: 8px;
		overflow: hidden;
		background-color: #fff;
		margin: 15px 0;
		padding-top: 10px;
		padding-bottom: 10px;

		&:nth-child(1) {
			margin-top: 0;
		}

		.infoTitle {
			height: 50px;
			line-height: 50px;
			font-size: 16px;
			font-weight: 700;
			background-color: #f5f8ff;
			padding-left: 10px;
			// border-bottom: 1px solid #ececec;
		}

		.infoList {
			background-color: #fff;
			padding-left: 18px;
			font-size: 16px;
			// line-height: 34px;

			span {
				color: #000;
				// display: inline-block;
				display: inline;
			}

			.infoLabel {
				color: #666;
				padding: 5px;
				line-height: 32px;
			}

			.contentText {
				padding: 0 10px;
			}

			.imageContent {
				width: 320px;

				padding: 0;
				margin: 0 5px;
				margin-top: -5px;
				cursor: pointer;
			}

			.videoContent {
				width: 320px;
				height: 200px;
				cursor: pointer;
			}

			.pdfContent {
				color: #409eff;
				cursor: pointer;
			}
		}
	}

	// 区块链标识
	.identification {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 15px;
		margin-bottom: 16px;

		.idCationBtn {
			width: 95%;
			height: 40px;
			background-color: #ecf5ff;
			border: 1px solid #b3d8ff;
			color: #409eff;
			// box-shadow: 0px 3px 6px rgba(56, 126, 253, 0.3);
			opacity: 1;
			border-radius: 5px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding: 0 15px;

			&:hover {
				color: #fff;
				background-color: #409eff;
			}
		}
	}

	.footer {
		position: absolute;
		bottom: 0;
		left: 0;
	}

	/* 图片预览 */
	.picMask {
		position: absolute;
		width: 100vw;
		height: 110%;
		background-color: rgba(51, 51, 51, 0.514);
		top: -65px;
		left: 0;
		z-index: 22;
	}

	.picMask .bigPic {
		position: fixed;
		left: 50vw;
		top: 50vh;
		transform: translate(-50%, -50%);
		background-color: #fff;
		z-index: 30;
	}

	.picMask .bigPic img {
		width: 100vw;
	}



}

.van-field__body {
	border: 1px solid #545050;
	align-items: center;
	height: 40px;
	border-radius: 5px;
}

.home-check {
	width: 100vw;
	height: 100vh;
	margin: 0 auto;
	position: relative;
	background-color: #2c43e8;
	background-image: url("../assets/img/Identification/u1.png");
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: calc(100% - 100px);

	.headerBg {
		height: 140px;
		background-image: url("../assets/img/Identification/u3.png");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 36px;
		font-weight: 700;
		color: #fff;
		position: relative;

		.checkTop {
			font-size: 30px;
		}
	}

	.checkCode {
		flex: 1;

		.checkProd {
			background-color: #fff;
			width: 90%;
			margin: 0 auto;
			height: 50px;
			border-radius: 5px;
			padding-left: 15px;
			font-size: 12px;
			line-height: 20px;
			padding-top: 5px;

			p {
				width: 95%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
			}

		}

		.checkTop {
			margin-top: 10px;
			background-color: #fff;
			border-radius: 5px;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			font-size: 14px;

			.successImg {
				width: 42px;
				height: 42px;
				margin-right: 10px;
			}

			.rightText {
				flex: 1;
				line-height: 25px;

				h3 {
					color: #148df5;
				}

				p {
					font-size: 14px;
				}
			}
		}

		.checkContent {
			height: 420px;
			background-color: #fff;
			border-radius: 5px;
			width: 90%;
			margin: 10px auto;
			position: relative;

			.checktips {
				width: 80%;
				margin: 0 auto;
				padding-top: 15px;
				line-height: 20px;
				font-size: 12px;
			}

			.logo {
				width: 100%;
				margin: 0 auto;
				text-align: center;
				position: absolute;
				bottom: 20px;
				margin-top: 45px;
			}

			.checkText {
				text-align: center;
				padding-top: 40px;
				font-size: 30px;
				font-weight: bold;

			}

			.formcheck {
				width: 100%;

				.inputCheck {
					width: 80%;
					margin: 0 auto;
					margin-top: 50px;


					.inputCheckInp {
						width: 100%;
						height: 40px;
						border-radius: 5px;
						border: 1px solid #4e4e4e;
						text-align: center;
					}
				}

				.checkBtn {
					margin-top: 20px;
					widows: 80%;
					border-radius: 10px;

				}
			}
		}
	}
}</style>
