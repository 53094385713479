import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:traceNo/:num',
    name: 'Home',
    component: Home
  },

  {
    path: '/:traceNo/:num/:code',
    name: 'Home',
    component: Home
  },

  {
    path: '/identification',
    name: 'identification',
    component: () => import('../views/Identification.vue')
  },

  {
    path: '/pdfPreview',
    name: 'pdfPreview',
    component: () => import('../components/pdfPreview/PdfPreview')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  },

]

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({
    y: 0
  }), // 管理滚动行为 如果出现滚动 切换页面后 让页面回到顶部
})

export default router