import {
  http
} from './request.js'

// 获取信息
export const getInfo = (params) => {
  return http({
    url: '/api/query/info',
    params,
    method: 'post'
  })
}

export const check = (params) => {
  return http({
    url: '/api/query/check',
    params,
    method: 'post'
  })
}