import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 引入vant
import Vant from 'vant';
import i18n from './lang';
import 'vant/lib/index.css';

Vue.use(Vant);

// 轻提示
import {
  Toast
} from 'vant';
Vue.use(Toast);

Vue.prototype.$toast = Toast;



import VueImageSwipe from 'vue-image-swipe'
import 'vue-image-swipe/dist/vue-image-swipe.css'
Vue.use(VueImageSwipe);

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')