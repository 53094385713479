const message = {
    toptips:"防篡改，可追溯，公开透明",
    traning_code:"溯源码",
    scantimes:'扫码次数',
    productinfo:'产品信息',
    batch_no:'批次号',
    product_name:'产品名称',
    blockchain_ident:'交易hash',
    not_support_video:'你的手机浏览器内核不支持video标签',
    blockchain_verify:'区块链查验',
    pass:'验证通过',
    blockchian:"区块链",
    hxchain:'海星链',
    height:'区块高度',
    evidence_time:'存证时间',
    signature:'数字签名',
    txhash:'交易hash',
    pass_content:'数据已在区块链加密存证，未被篡改！',
    inputCheckCode:'请输入6位溯源验证码',
    input_code:'请输入6位溯源验证码',
    query:"查询",
    queryTitle:"溯源验真查询",
    inputTip1:"请输入溯源验证码",
    errCheckCode:"抱歉，您输入的验证码无效，请核对后再次输入",
    tooManyQuery:"查询错误次数过多，请稍后重试",
    tips2:"一物一码，区块链溯源，全球品质保障！",
    goodsInfo:"商品信息",

}
module.exports = message