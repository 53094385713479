const message = {
    toptips:"Tamperproof, Traceable, Open",
    traning_code:"Tracing code",
    scantimes:'Scan times',
    productinfo:'Product info',
    batch_no:'Batch No.',
    product_name:'Product name',
    blockchain_ident:'Txhash',
    not_support_video:'Your browser kernel does not support video tags',
    blockchain_verify:'Blockchain verify',
    pass:'PASS',
    blockchian:"Blockchain",
    hxchain:'HXChain',
    height:'height',
    evidence_time:'Evidence time',
    signature:'Digital signature',
    txhash:'Txhash',
    pass_content:'Has been encrypted and stored in the blockchain,  not been tampered with！',
    inputCheckCode:'Input the 6-digit verification code',
    input_code:'Please input the 6-digit code',
    query:"Query",
    queryTitle:"Tracing Query",
    errCheckCode:"Sorry, the verification code you entered is invalid. Please verify and re-enter it",
    tooManyQuery:"Too many query errors. Please try again later",
    tips2:"One thing, one code, blockchain traceability, global quality assurance!",
    goodsInfo:"Goods Info",
  }

 
  module.exports = message