import axios from 'axios'
// import {
//   this_
// } from '../main'



console.log('请求查看环境', process.env.VUE_APP_BASE_URL);
// const PROD_URL = 'http://api.exam.test.cniblockchain.com:88/';  
// const baseURL = (process.env.NODE_ENV === "development") ? DEV_URL : PROD_URL

const baseURL = process.env.VUE_APP_BASE_URL
export const http = new axios.create({
  baseURL,
  timeout: 30000
})

// http.interceptors.response.use(res => {

//   return res
// }, err => {
//   if (err.response) {
//     let status = err.response.status;
//     let code = err.response.data.code;
//     let text = err.response.statusText || '请求出错'
//     console.log(`status:${err.response.status}`);
//     // 网络出现500 或 400
//     if (/^50[0-9]/.test(status)) {
//       // 服务器错误 
//       this_.$msg.message({
//         icon: 'warning',
//         content: text,
//       });
//     }
//     if (/^4[0-1][0-9]/.test(code)) {
//       this_.$msg.message({
//         icon: 'warning',
//         content: text,
//       });
//     }
//     return Promise.reject(err.response)
//   }
//   return Promise.reject(err.response)
// })