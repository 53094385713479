<template>
  <div class="footer-page">
    <div class="lineBox"></div>
  </div>
</template>

<script setup>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.footer-page {
  width: 100%;
  max-width: 660px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f5f4;
  .lineBox {
    width: 133px;
    height: 4px;
    background: #000000;
    opacity: 1;
    border-radius: 10px;
  }
}
</style>